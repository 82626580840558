.app__footerOverlay {
    width: 100%;
    height: 100%;
    z-index:  -1;

    display: flex;
    flex-direction: column;
    position: absolute; 

    margin-top: -4rem;
}

 /* .app__footerOverlay-black {
    height: 45%;
    background: var(--color-black);
}  */

 .app__footerOverlay-img {
    height: 100%;
} 




